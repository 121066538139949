//
// Fonts
//
import '@fontsource-variable/comfortaa'
import '@fontsource/righteous'

//
// Styles
//
import '../css/style.css'

//
// Initialize carousels
//
function setUpCarousels() {
    const inactiveClass = 'opacity-70'
    const carousels = [...document.querySelectorAll('.carousel')]

    if (carousels.length) {
        Promise.all([
            import('@glidejs/glide'),
            import('@glidejs/glide/dist/css/glide.core.css'),
        ]).then(([{ default: Glide }]) => {
            carousels.forEach((carousel) => {
                const bullets = [
                    ...carousel.querySelectorAll('.glide-bullets a'),
                ]
                const next = carousel.querySelector('.glide-next')
                const previous = carousel.querySelector('.glide-previous')
                const glide = new Glide(carousel.querySelector('.glide'), {
                    type: 'slider',
                    perView: carousel.classList.contains('single') ? 1 : 3,
                    autoplay: carousel.classList.contains('single')
                        ? 5000
                        : false,
                    gap: 32,
                    peek: {
                        before: 0,
                        after: 120,
                    },
                    breakpoints: {
                        640: {
                            perView: 1,
                            gap: 16,
                            peek: {
                                before: carousel.classList.contains('single')
                                    ? 0
                                    : 32,
                                after: carousel.classList.contains('single')
                                    ? 0
                                    : 32,
                            },
                        },
                        1024: {
                            perView: carousel.classList.contains('single')
                                ? 1
                                : 2,
                            gap: 16,
                            peek: {
                                before: carousel.classList.contains('single')
                                    ? 0
                                    : 32,
                                after: carousel.classList.contains('single')
                                    ? 0
                                    : 32,
                            },
                        },
                        1800: {
                            perView: carousel.classList.contains('single')
                                ? 1
                                : 2,
                            gap: 32,
                            peek: {
                                before: carousel.classList.contains('single')
                                    ? 0
                                    : 0,
                                after: carousel.classList.contains('single')
                                    ? 0
                                    : 120,
                            },
                        },
                    },
                }).mount()

                glide.on('run.after', () => {
                    const activeBullet = carousel.querySelector(
                        `.glide-bullets a[data-slide='${glide.index}']`
                    )

                    bullets.forEach((bullet) =>
                        bullet.classList.add(inactiveClass)
                    )

                    if (activeBullet) {
                        activeBullet.classList.remove(inactiveClass)
                    }
                })

                next.addEventListener('click', () => glide.go('>'))
                previous.addEventListener('click', () => glide.go('<'))
            })
        })
    }
}

//
// Initialize navigation toggle
//
function setUpNavigation() {
    const menu = document.getElementById('header-menu')
    const menuToggler = document.getElementById('header-menu-toggle')

    if (menu && menuToggler) {
        menuToggler.addEventListener('click', () => {
            menu.classList.toggle('hidden')
            menuToggler.classList.toggle('open')
        })
    }
}

//
// Animations
//
function setUpAnimations() {
    const onIntersecting = (target) => {
        const items = [...target.querySelectorAll('[data-appear]')]

        items.forEach((item, index) => {
            const className = item.dataset.appear || 'animated'
            const delay =
                (parseInt(item.dataset.appearDelay) || 0) + index * 100

            setTimeout(() => item.classList.add(className), delay)
        })
    }

    const observer = !!window.IntersectionObserver
        ? new IntersectionObserver(
              (entries, observer) => {
                  for (const entry of entries) {
                      if (entry.intersectionRatio > 0) {
                          onIntersecting(entry.target)
                          observer.unobserve(entry.target)
                      }
                  }
              },
              {
                  rootMargin: '0% 0% -30% 0%',
                  threshold: 0,
              }
          )
        : null

    const staggers = [...document.querySelectorAll('[data-stagger]')]

    staggers.forEach((stagger) =>
        observer ? observer.observe(stagger) : onIntersecting(stagger)
    )
}

//
// Tooltips
//
function setUpTooltips() {
    const tooltips = [
        ...document.querySelectorAll('[data-component="tooltip"]'),
    ]

    tooltips.forEach((tooltip) => {
        const parent = tooltip.parentNode
        const toggle = () => tooltip.classList.toggle('hidden')

        parent.addEventListener('mouseover', toggle)
        parent.addEventListener('mouseout', toggle)
    })
}

setUpAnimations()
setUpNavigation()
setUpCarousels()
setUpTooltips()
